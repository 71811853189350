import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Container, CardBody, Col, Row, InputGroup } from "reactstrap";
import { MoondanceContext } from '../../App';
import TransactionsGraph from "../lib/charts/transactionsGraph";
import UseMoondanceApi from "../lib/api";
import MoondanceLayout from "../lib/layout";
import { buildAccountParameter } from "../lib/formatData";

import MoondanceTable from "../lib/components/MoondanceTable.js";

import Flatpickr from "react-flatpickr";
import { Link } from 'react-router-dom';

const currentDate = new Date();
const twoYearsBefore = currentDate.getFullYear() - 7;
const defaultStartDate = new Date(twoYearsBefore, 0, 1);

function Transactions(props) {

    const moondanceApi = UseMoondanceApi();
    const { selectedAccountList } = useContext(MoondanceContext);
    const { selectedStatus } = useContext(MoondanceContext);
    const [DATA, setData] = useState({});
    const [DATAPERGROUP, setDataPerGroup] = useState({});
    const [loading, setLoading] = useState(true);
    const [groupTransaction, setGroupTransaction] = useState('M');
    const [transactionKeyword, setTransactionKeyword] = useState('');
    const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);


    const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate);
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(currentDate.setMonth(currentDate.getMonth() + 3)));
    const flatpickrRef_start = useRef(null);
    const flatpickrRef_end = useRef(null);

    const { toggleTransactionModal, closeTransactionModal } = useContext(MoondanceContext);
    const { transactionModalData, setTransactionModalData } = useContext(MoondanceContext);


    function openTransactionModal(data) {
        setTransactionModalData(data)
        toggleTransactionModal(true);
    }



    function handleSelectStartDate(selectedDates, dateStr, instance) {
        //console.log('dateStr', dateStr)
        setSelectedStartDate(dateStr)
    }
    function handleSelectEndDate(selectedDates, dateStr, instance) {
        setSelectedEndDate(dateStr)
    }
    async function fetchData() {
        if (selectedAccountList) {
            var api_parameters = {
                account: buildAccountParameter(selectedAccountList),
                status: buildAccountParameter(selectedStatus),
                keyword: transactionKeyword,
                userCurrency: userCurrency,
            }
            //console.log('fetchData',selectedStartDate)
            if (selectedStartDate instanceof Date) {

                var formattedDate =

                    selectedStartDate.getFullYear() + '-' +
                    ("0" + (selectedStartDate.getMonth() + 1)).slice(-2) + "-" +
                    ("0" + selectedStartDate.getDate()).slice(-2)

                //console.log('isdate', formattedDate)
                api_parameters.date_start = formattedDate
            } else {
                var parts = selectedStartDate.split('/');
                var newDateStr = `${parts[2]}-${parts[1]}-${parts[0]}`;
                api_parameters.date_start = newDateStr
            }
            if (selectedEndDate instanceof Date) {
                var formattedDate =
                    selectedEndDate.getFullYear() + "-" +
                    ("0" + (selectedEndDate.getMonth() + 1)).slice(-2) + "-" +
                    ("0" + selectedEndDate.getDate()).slice(-2)

                api_parameters.date_end = formattedDate

            } else {
                var parts = selectedEndDate.split('/');
                var newDateStr = `${parts[2]}-${parts[1]}-${parts[0]}`;
                api_parameters.date_end = newDateStr
            }
            try {
                var data = {};
                var data_transactions = await moondanceApi.fetchTransactions(api_parameters);
                if (data_transactions.data.length > 0) {
                    data.transactions = data_transactions.data
                    data.transactions.sort((a, b) => b.timestamp - a.timestamp);
                    setData(data);
                    let tempDataPerType = data.transactions.reduce((acc, item) => {
                        acc[item.type] = (acc[item.type] || 0) + item.value_in_user_currency;
                        return acc;
                    }, {});
                    let dataPerType = Object.keys(tempDataPerType).map(key => ({
                        type: key,
                        value_in_user_currency: tempDataPerType[key]
                    }));
                    setDataPerGroup(dataPerType)
                }
                setLoading(false)

            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchData();
    }, [selectedAccountList, selectedStatus, lastUpdate, selectedStartDate, selectedEndDate,transactionKeyword]);


    return (

        <React.Fragment >
            {(props && props.tabs ?

                (DATA.transactions ?
                    <>  <Card >
                        <CardBody>
                            <Row className="h-100">
                                <Col xl={3} sm={12} >

                                    <label>Start Date</label>
                                    <InputGroup>
                                        <Flatpickr

                                            className="form-control d-block"
                                            onChange={handleSelectStartDate}
                                            value={selectedStartDate}
                                            options={{
                                                clickOpens: false,  // ensure this is true
                                                allowInput: true,   // ensure direct input is allowed
                                                dateFormat: "d/m/Y",

                                            }}

                                            ref={flatpickrRef_start}
                                        />
                                        <div className="input-group-append" onClick={() => flatpickrRef_start.current.flatpickr.open()}>
                                            <span className="input-group-text">
                                                <i className="mdi mdi-calendar"></i>
                                            </span>
                                        </div>
                                    </InputGroup>
                                </Col>
                                <Col xl={3} sm={12} >
                                    <label>End Date</label>
                                    <InputGroup>


                                        <Flatpickr

                                            className="form-control d-block"
                                            onChange={handleSelectEndDate}
                                            value={selectedEndDate}
                                            options={{
                                                clickOpens: false,  // ensure this is true
                                                allowInput: true,   // ensure direct input is allowed
                                                dateFormat: "d/m/Y",

                                            }}

                                            ref={flatpickrRef_end}
                                        />
                                        <div className="input-group-append" onClick={() => flatpickrRef_end.current.flatpickr.open()}>
                                            <span className="input-group-text">
                                                <i className="mdi mdi-calendar"></i>
                                            </span>
                                        </div>
                                    </InputGroup>
                                </Col>

                                <Col xl={3} sm={12} >
                                    <label >Keyword :</label>
                                    <InputGroup>
                                        <input
                                            type="text"
                                            className="form-control "
                                            value={transactionKeyword}
                                            onChange={e => setTransactionKeyword(e.target.value)}
                                           
                                        />
                                    </InputGroup>

                                </Col>
                                <Col xl={3} sm={12}  >
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn  btn-primary" onClick={() => fetchData()}>Refresh</button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>


                        <Row>

                            <Col xl={8} sm={12}>

                                <TransactionsGraph
                                    chart={`table-${DATA.transactions}-${selectedStartDate}-${selectedEndDate}-${lastUpdate}-${transactionKeyword}`}
                                    data={DATA.transactions}
                                />
                            </Col>
                            {(DATAPERGROUP && DATAPERGROUP.length > 0 ?
                                <Col xl={4} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <MoondanceTable
                                                key={`table-${DATA.transactions}-${selectedStartDate}-${selectedEndDate}-${lastUpdate}-pergroup`}
                                                title="Transactions per type"
                                                rows={DATAPERGROUP}

                                                hideAiButton={true}
                                                columns={[
                                                    { label: 'Type', field: 'type', format: 'findInArray', formatUnit: MoondanceLayout.transactionType, align: 'left' },
                                                    { label: 'Value (' + userCurrency + ')', field: 'value_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                                ]}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col> : <></>)}
                            <Col xl={12} sm={12}>
                                <Card>
                                    <CardBody>

                                        {(DATA.transactions && DATA.transactions.length > 0 ? (

                                            <MoondanceTable
                                                key={`table-${DATA.transactions}-${selectedStartDate}-${selectedEndDate}-${lastUpdate}`}
                                                title="List Transactions"
                                                rows={DATA.transactions}
                                                defaultSort={{ key: 'timestamp', direction: 'descending' }}
                                                columns={[
                                                    { label: 'Id', field: 'id' },
                                                    { label: 'Date', field: 'timestamp', format: 'timestampToDate' },
                                                    { label: 'Type', field: 'type', format: 'findInArray', formatUnit: MoondanceLayout.transactionType, align: 'right' },
                                                    { label: 'Shares (+/-)', field: 'shares', customFormat: (row) => { return (MoondanceLayout.formatNumber(row['shares'], 2)) }, format: 'number', align: 'right', showTotal: true },

                                                    { label: 'Value (' + userCurrency + ')', field: 'value_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                                    {
                                                        label: 'Asset',
                                                        field: 'asset_name',
                                                        customFormat: (row) => {
                                                            return (
                                                                <Link className="primary" to={'/position/' + row.position_id}>{row.asset_name}</Link>)
                                                        }
                                                    },
                                                    { label: 'Note', field: 'note', },
                                                    {
                                                        label: 'Amount', field: 'value_in_transaction_currency', align: 'right',
                                                        customFormat: (row) => {
                                                            let sign = ''
                                                            return row.value_in_transaction_currency.toLocaleString('fr-FR', { style: "currency", currency: row.transaction_currency, maximumFractionDigits: 0 })

                                                        },
                                                    },

                                                    { label: 'Bank', field: 'bank', align: 'right' },

                                                    { label: 'Currency', field: 'transaction_currency', align: 'right' }
                                                    ,
                                                    {
                                                        label: 'Note', field: '', align: 'right', customFormat: (row) => {
                                                            let sign = ''
                                                            let returnVal = ''
                                                            if (MoondanceLayout.transactionType.find(item => item.value === row.type).sign) {
                                                                sign = MoondanceLayout.transactionType.find(item => item.value === row.type).sign
                                                            }
                                                            if (
                                                                (sign === '-' && row.value_in_transaction_currency > 0)
                                                                || (sign === '+' && row.value_in_transaction_currency < 0)
                                                            ) {
                                                                return <><p className="badge bg-danger"> Invalid Sign </p></>
                                                            } else {
                                                                return returnVal
                                                            }
                                                        },
                                                    }

                                                ]}

                                                actions={[{ label: 'Edit', condition: 'user_can_edit_transaction', icon: 'fas fa-edit', onClick: (row) => openTransactionModal(row) }]}
                                            />
                                        ) : < p>No transactions</p>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </> :
                    <p>Loading ...</p>
                )
                :

                <div className="page-content">
                    <Container fluid={true}>

                        <Row style={{ marginBottom: '1em' }}>

                            <Col xl={8} sm={12} md={12} xs={12}>
                                <h1><i className="mdi mdi-desktop-mac-dashboard"></i> List Transactions</h1>
                            </Col>
                            <Col xl={4} sm={12} md={12} xs={12}>
                                <p style={{ fontSize: "0.8em" }}><b>Selected Account :</b>{MoondanceLayout.displayArrayLabels(selectedAccountList)}<br />
                                    <b>Selected Status :</b>{MoondanceLayout.displayArrayLabels(selectedStatus)}<br />
                                    <b>Currency :</b>{userCurrency}
                                </p>
                            </Col>
                        </Row>
                        <hr />

                        {loading && (<p>Loading...</p>)}
                        {DATA.transactions && (
                            <>
                                <Row className="h-100">
                                    <Col xl={3} sm={12} >

                                        <label>Start Date</label>
                                        <InputGroup>
                                            <Flatpickr

                                                className="form-control d-block"
                                                onChange={handleSelectStartDate}
                                                value={selectedStartDate}
                                                options={{
                                                    clickOpens: false,  // ensure this is true
                                                    allowInput: true,   // ensure direct input is allowed
                                                    dateFormat: "d/m/Y",

                                                }}

                                                ref={flatpickrRef_start}
                                            />
                                            <div className="input-group-append" onClick={() => flatpickrRef_start.current.flatpickr.open()}>
                                                <span className="input-group-text">
                                                    <i className="mdi mdi-calendar"></i>
                                                </span>
                                            </div>
                                        </InputGroup>
                                    </Col>
                                    <Col xl={3} sm={12} >
                                        <label>End Date</label>
                                        <InputGroup>


                                            <Flatpickr

                                                className="form-control d-block"
                                                onChange={handleSelectEndDate}
                                                value={selectedEndDate}
                                                options={{
                                                    clickOpens: false,  // ensure this is true
                                                    allowInput: true,   // ensure direct input is allowed
                                                    dateFormat: "d/m/Y",

                                                }}

                                                ref={flatpickrRef_end}
                                            />
                                            <div className="input-group-append" onClick={() => flatpickrRef_end.current.flatpickr.open()}>
                                                <span className="input-group-text">
                                                    <i className="mdi mdi-calendar"></i>
                                                </span>
                                            </div>
                                        </InputGroup>
                                    </Col>
                                    <Col xl={3} sm={12}  >
                                        <label>&nbsp;</label><br />
                                        <button type="button" className="btn  btn-primary" onClick={() => fetchData()}>Refresh</button>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xl={12} sm={12}>


                                        <TransactionsGraph data={DATA.transactions} />
                                    </Col>
                                    <Col xl={12} sm={12}>
                                        <Card>
                                            <CardBody>
                                                {(DATA.transactions && DATA.transactions.length > 0 ? (
                                                    <MoondanceTable
                                                        key={`${DATA.transactions}-${lastUpdate}`}
                                                        title="List Transactions"
                                                        rows={DATA.transactions}
                                                        defaultSort={{ key: 'timestamp', direction: 'ascending' }}
                                                        columns={[
                                                            { label: 'Id', field: 'id' },
                                                            { label: 'Date', field: 'timestamp', format: 'timestampToDate' },

                                                            { label: 'Type', field: 'type', format: 'findInArray', formatUnit: MoondanceLayout.transactionType, align: 'right' },
                                                            { label: 'Shares (+/-)', field: 'shares', format: 'number', align: 'right', showTotal: true },

                                                            { label: 'Value (' + userCurrency + ')', field: 'value_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },

                                                            {
                                                                label: 'Asset',
                                                                field: 'asset_name',
                                                                customFormat: (row) => {
                                                                    return (
                                                                        <Link className="success" to={'/position/' + row.position_id}>{row.asset_name}</Link>)
                                                                }
                                                            },
                                                            { label: 'Note', field: 'note', },

                                                            { label: 'Bank', field: 'bank', align: 'right' },

                                                            {
                                                                label: 'Amount', field: 'value_in_transaction_currency', align: 'right',
                                                                customFormat: (row) => { return row.value_in_transaction_currency.toLocaleString('fr-FR', { style: "currency", currency: row.transaction_currency, maximumFractionDigits: 0 }) },
                                                            },
                                                            { label: 'Currency', field: 'transaction_currency', align: 'right' }


                                                        ]}

                                                        actions={[{
                                                            label: 'Edit',
                                                            condition: 'user_can_edit_transaction',
                                                            icon: 'fas fa-edit',
                                                            onClick: (row) => openTransactionModal(row)
                                                        }]}
                                                    />
                                                ) : < p>No transactions</p>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Container>
                </div>
            )}
        </React.Fragment >
    )

}

export default Transactions;


/*

                                    {(DATA.transactions && DATA.transactions.length > 0 ? (
                                        <MoondanceTable
                                            rows={DATA.transactions}
                                            defaultSort={{ key: 'timestamp', direction: 'ascending' }}
                                            columns={[
                                                { label: 'Id', field: 'id' },
                                                { label: 'Date', field: 'timestamp', format: 'timestampToDate' },
                                                { label: 'type', field: 'type', format: 'findInArray', formatUnit: MoondanceLayout.transactionType, align: 'right' },
                                                { label: 'Note', field: 'note', },
                                                {   label: 'Asset', 
                                                    field: 'asset_name',                                                 
                                                    customFormat: (row) => { return (
                                                        <Link className="info" to={'/position/' + row.position_id}>{row.asset_name}</Link>)
                                                    }
                                                },

                                                { label: 'Bank', field: 'bank', align: 'right' },

                                                { label: 'Value (' + userCurrency + ')', field: 'value_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                                {
                                                    label: 'Amount', field: 'value_in_transaction_currency', align: 'right',
                                                    customFormat: (row) => { return row.value_in_transaction_currency.toLocaleString('fr-FR', { style: "currency", currency: row.transaction_currency, maximumFractionDigits: 0 })},
                                                },
                                                { label: 'Currency', field: 'transaction_currency',  align: 'right' }


                                            ]}

                                            actions={[{ label: 'Edit', icon: 'fas fa-edit', onClick: (row) => openTransactionModal(row) }]}
                                        />
                                    ) : < p>No transactions</p>
                                    )}
*/