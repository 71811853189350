
import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { MoondanceContext } from '../../App';
import UseMoondanceApi from "../lib/api";

import MoondanceLayout from "../lib/layout.js";
import MoondanceTable from "../lib/components/MoondanceTable.js";
import Logs from '../admin/logs.js'
import Accounts from '../admin/accounts.js'
import { Link } from 'react-router-dom';


const Clients = () => {
    let Navigate = useNavigate();

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const moondanceApi = UseMoondanceApi();
    const { lastUpdate } = useContext(MoondanceContext);
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [clientData, setClientData] = useState({})
    const [userList, setUserList] = useState({})
    const [accountList, setAccountList] = useState({})
    const [positionsList, setPositionsList] = useState({})
    const [activeTab, setactiveTab] = useState("Users");
    const toggle = (tab) => { if (activeTab !== tab) { setactiveTab(tab); } };
    const params = useParams();
    const id = params.id;

    useEffect(() => {
        
        if (activeTab == 'Users') { fetchClientUserList(); }
        if (activeTab == 'Accounts') { fetchClientAccountList(); }
        if (activeTab == 'Positions') { fetchClientPositionsList(); }
    }, [lastUpdate, params, activeTab]);



    async function fetchClientDetails() {
        const clientDetails = await moondanceApi.admin_get_client({ id: params.id });
        if (clientDetails && clientDetails.data) {
            setClientData(clientDetails.data)
        }
    }
    async function fetchClientUserList() {
        const userList = await moondanceApi.admin_get_client_users({ id: params.id });
        if (userList && userList.data) {
            setUserList(userList.data)
        }
    }
    async function fetchClientAccountList() {
        const accountList = await moondanceApi.admin_get_client_accounts({ id: params.id });
        if (accountList && accountList.data) {
            setAccountList(accountList.data)
        }
    }
    async function fetchClientPositionsList() {
        const positionsList = await moondanceApi.admin_get_client_positions({ id: params.id });
        console.log(positionsList)
        if (positionsList && positionsList.data) {
            setPositionsList(positionsList.data)
        }
    }
    return (
        <React.Fragment >

            <div className='page-content' >

                <Row style={{ marginBottom: '1em' }}>
                    <div className="flex">

                        <h1>
                            <i className="mdi mdi-desktop-mac-dashboard"></i> {clientData.company_name} </h1>
                        <Link className="btn btn-outline-primary" to={'/admin'}>Back to Admin home</Link>

                    </div>
                </Row>
                <hr />
                <Container fluid={true}>

                    <Card>
                        <CardBody>
                            <Row>
                                <h2>{clientData.company_name} </h2>
                                <Col xl={6} sm={12}>
                                    <p>
                                        Contact : {clientData.contact_first_name} {clientData.contact_last_name}<br />
                                        Email : {clientData.contact_email}<br />
                                        Number of Positions : {clientData.current_positions_count}<br />
                                        Number of Accounts (Portofolio) : {clientData.current_accounts_count} / {clientData.max_accounts_allowed} (Max)<br />
                                        Number of users (Login) : {clientData.current_users_count} / {clientData.max_users_allowed} (Max)<br />

                                    </p>
                                </Col>
                                <Col xl={6} sm={12}>
                                    <p>
                                        {clientData.company_name} (ref:#{clientData.id})<br />
                                        {clientData.company_address_1}<br />
                                        {clientData.company_postcode} {clientData.company_city}<br />

                                    </p>
                                </Col>

                                <Nav tabs>

                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={(activeTab === 'Users' ? "active nav-link " : "")}
                                            onClick={() => { toggle("Users"); }}
                                        >
                                            <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Users
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={(activeTab === 'Accounts' ? "active nav-link " : "")}
                                            onClick={() => { toggle("Accounts"); }}
                                        >
                                            <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Accounts
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={(activeTab === 'Positions' ? "active nav-link " : "")}
                                            onClick={() => { toggle("Positions"); }}
                                        >
                                            <i className="fas fa-chess me-1 align-middle"> </i>{" "}Positions
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <Row>

                                    <TabContent activeTab={activeTab} className="p-3">
                                        <TabPane tabId="Users" id="Users">
                                            {(userList && userList.length > 0 ? (
                                                <MoondanceTable
                                                    key={`${userList}-${lastUpdate}`}
                                                    title="Users  List"
                                                    rows={userList}
                                                    defaultSort={{ key: 'last_name', direction: 'ascending' }}
                                                    paginationSize={25}
                                                    hideAiButton={true}
                                                    columns={[
                                                        {
                                                            label: 'Id', field: 'id',
                                                            customFormat: (row) => {
                                                                return (
                                                                    <Link className="success" to={'/admin/users/' + row.id}>{row.id}</Link>)
                                                            },


                                                        },
                                                        { label: 'Account', field: 'customer_account_name' },
                                                        { label: 'First Name', field: 'first_name' },
                                                        { label: 'Last Name', field: 'last_name' },
                                                        { label: 'Email', field: 'email', customFormat: (row) => {
                                                            return (
                                                                <Link className="success" to={'/admin/users/' + row.id}>{row.email}</Link>)
                                                        }},
                                                        { label: 'Tel', field: 'tel' },
                                                        { label: 'currency', field: 'currency' },
                                                        { label: '#Positions', field: 'number_of_positions' },
                                                        { label: 'SuperAdmin', field: 'superadmin_access_level' },
                                                        { label: 'Login past 3 Months', field: 'number_of_login_past_3Months' },
                                                        { label: 'Login Last 30 days', field: 'number_of_login_past_30days' },
                                                        { label: 'Alerts Last 30 days', field: 'number_of_alert_past_30days' },


                                                    ]}


                                                />
                                            ) : < p>No Data</p>
                                            )}
                                        </TabPane>
                                        <TabPane tabId="Accounts" id="Accounts">

                                            {(accountList && accountList.length > 0 ? (
                                                <MoondanceTable
                                                    key={`accountList-${lastUpdate}`}
                                                    title="Accounts list"
                                                    rows={accountList}
                                                    defaultSort={{ key: 'company_name', direction: 'ascending' }}
                                                    paginationSize={25}
                                                    hideAiButton={true}
                                                    columns={[
                                                        { label: 'Id', field: 'id' },
                                                        { label: 'Name', field: 'name' },
                                                        { label: '#Users', field: 'number_of_users' },
                                                        { label: '#Admin', field: 'number_of_admins' },
                                                        { label: '# Open Positions', field: 'number_of_open_positions', showTotal: true },
                                                        { label: '#Positions', field: 'number_of_positions', showTotal: true },
                                                    ]}
                                                />
                                            ) : < p>No Data</p>
                                            )}
                                        </TabPane>
                                        <TabPane tabId="Positions" id="Positions">

                                            {(positionsList && positionsList.length > 0 ? (
                                                <MoondanceTable
                                                    key={`positionList-${lastUpdate}`}
                                                    title="Positions list"
                                                    rows={positionsList}
                                                    defaultSort={{ key: 'asset_name', direction: 'ascending' }}
                                                    paginationSize={25}
                                                    hideAiButton={true}
                                                    columns={[

                                                        { label: 'Status', field: 'position_status_label' },
                                                        { label: 'Id', field: 'position_id' },
                                                        { label: 'Name', field: 'asset_name' },
                                                        { label: 'Account', field: 'asset_account_name' },
                                                        { label: 'Vintage', field: 'asset_vintage_year' },

                                                        { label: 'Currency', field: 'asset_currency'},
                                                        { label: 'Commitment', field: 'position_commited_in_asset_currency', 
                                                        customFormat: (row) => { return (row['position_commited_in_asset_currency'] ? MoondanceLayout.formatCurrency(row['position_commited_in_asset_currency'], row['asset_currency']) : '') }, align: 'right',
                                                        showTotal: false },
                                                        { 
                                                            label: 'Commitment ('+userCurrency+')', 
                                                            field: 'position_commited_in_user_currency_at_inception_rate', 
                                                            customFormat: (row) => { return (row['position_commited_in_user_currency_at_inception_rate'] ? MoondanceLayout.formatCurrency(row['position_commited_in_user_currency_at_inception_rate'], userCurrency) : '') }, 
                                                             align: 'right',
                                                            format: 'currency', 
                                                            formatUnit: userCurrency,
                                                            showTotal: true
                                                        },
                                                        { 
                                                            label: 'Last NAV ('+userCurrency+')', 
                                                            field: 'position_last_nav_in_user_currency', 
                                                            customFormat: (row) => { return (row['position_last_nav_in_user_currency'] ? MoondanceLayout.formatCurrency(row['position_last_nav_in_user_currency'], userCurrency) : '') }, 
                                                             align: 'right',
                                                            format: 'currency', 
                                                            formatUnit: userCurrency,
                                                            showTotal: true
                                                        },



                                                    ]}
                                                />
                                            ) : < p>No Data</p>
                                            )}
                                        </TabPane>
                                    </TabContent>

                                </Row>
                            </Row>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>

    )

}



export default Clients;
