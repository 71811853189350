import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import TopBar from '../publicWebsite/topbar.js';
import Carousel from '../publicWebsite/carousel.js';
import Contact from '../publicWebsite/contactForm.js';
import { Link } from "react-router-dom";

const PublicFooter = () => {


    return (

        <React.Fragment>
    <div id="homeFooter" >
                @2024 Made in Belgium, All Rights Reserved  COGESPAR SA <Link to="/generalSalesConditions">General Sales Conditions</Link>
            </div>

        </React.Fragment>
    )

}


export default PublicFooter;