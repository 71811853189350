
import React, { useEffect, useState } from "react";
import logodark from "../../assets/images/moondance_logo_sm.png";
import logolight from "../../assets/images/moondance_logo_sm.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Alert, Col, Container, Form, Input, Label, Row } from "reactstrap";
import UseMoondanceApi from "../lib/api";
import { isPasswordStrong, isValidEmail } from "../lib/formatData";

import PublicFooter from '../publicWebsite/publicFooter.js';
import TopBar from '../publicWebsite/topbar.js';

const MoondanceRegister = () => {

    const moondanceApi = UseMoondanceApi();
    const [errors, setErrors] = useState({});
    const [submitResult, setSubmitResult] = useState(false);


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [telephone, setTelephone] = useState("");
    const [company, setCompany] = useState("");
    const [address, setAddress] = useState("");
    const [postcode, setPostcode] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");



    const [vat, setVat] = useState("");
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [currency, setCurrency] = useState("EUR");


    function handleFirstNameChange(e) {
        setFirstName(e.target.value);
        if (e.target.value.length < 2) {
            setErrors(prevErrors => ({
                ...prevErrors,
                firstName: 'First name must be at least 2 characters long'
            }));
        } else {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors.firstName; // Remove the 'firstName' key from errors
                return updatedErrors;
            });

        }
    }
    function handleLastNameChange(e) {
        setLastName(e.target.value);
        if (e.target.value.length < 2) {

            setErrors(prevErrors => ({
                ...prevErrors,
                lastName: 'Last name must be at least 2 characters long'
            }));

        } else {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors.lastName;
                return updatedErrors;
            });

        }
    }
    function handleTelephoneChange(e) {
        setTelephone(e.target.value);
    }
    function handleCurrencyChange(e) {
        setCurrency(e.target.value);
    }
    function handleCompanyChange(e) {
        setErrors(false)
        setCompany(e.target.value);
    }

    function handleAddressChange(e) {
        setErrors(false)
        setAddress(e.target.value);
    }
    function handleCityChange(e) {
        setErrors(false)
        setCity(e.target.value);
    }
    function handleCountryChange(e) {
        setErrors(false)
        setCountry(e.target.value);
    }
    function handlePostcodeChange(e) {
        setErrors(false)
        setPostcode(e.target.value);
    }


    function handleVatChange(e) {
        setErrors(false)
        setVat(e.target.value);
    }
    function handleEmailChange(e) {
        setEmail(e.target.value);
        if (!isValidEmail(e.target.value)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                email: 'Enter a valid email'
            }));
        } else {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors.email;
                return updatedErrors;
            });

        }
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
        if (!isPasswordStrong(e.target.value)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                password: 'You should choose a stronger password ! (At least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character)'
            }));
        } else {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors.password;
                return updatedErrors;
            });

        }



    }
    function handleConfirmPasswordChange(e) {
        setConfirmPassword(e.target.value);
        if (e.target.value != password) {
            setErrors(prevErrors => ({
                ...prevErrors,
                confirmPassword: 'Password and confirm password must be the same'
            }));
        } else {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors.confirmPassword;
                return updatedErrors;
            });
        }
    }


    function handleSubmit() {


        if (password !== confirmPassword || errors.firstName || errors.lastName || errors.email || errors.password || errors.confirmPassword || errors.currency || errors.vat || errors.company) {
            setErrors({ submitError: 'Please correct the errors before submitting the form' })

        } else {
            setErrors({})
            console.log('ready to Submit')
            var requestData = {
                first_name: firstName,
                last_name: lastName,
                tel: telephone,
                currency: currency,
                company: company,
                vat: vat,
                email: email,
                password: password,
                address: address,
                postcode: postcode,
                city: city,
                country: country

            }
            console.log(requestData)
            setSubmitResult('Moondance is not open yet')
            moondanceApi.signup(requestData).then(response => {
                setSubmitResult(response)
                console.log(response)
            })
                .catch(error => {
                    setSubmitResult(error)
                    console.log(error)
                });
        }


    }


    return (
        <React.Fragment>
            <TopBar noMenu='true' />
            <div className='page-content' >
                <Container fluid={true}>

                    <Row className="justify-content-center">
                        <Col xl={10}>
                            <Card>
                                <CardBody className="p-4">
                                    {submitResult && submitResult.success && (
                                        <div>
                                            <div className="d-grid mt-4">



                                                <h1>Thank You for Registering!</h1>
                                                <p>Please confirm your email address by clicking the link sent to your inbox. If you don't see the email, check your spam or junk folder.</p>


                                            </div>
                                        </div>
                                    )}
                                    {!submitResult.success && (

                                        <div>
                                       
                                            <h4 className="font-size-18 text-muted mt-2">
                                                Welcome to Moondance !
                                            </h4>
                                            <p className="mb-5 ">
                                                Please enter your details to register to moondance and have access to our services. You will be able to experiment our plateform for free with limited features. 
                                            </p>
                                            <Form
                                                className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-4">
                                                            <Label className="form-label">First name</Label>
                                                            <Input
                                                                name="first_name"
                                                                className="form-control"
                                                                placeholder="Enter your first name"
                                                                value={firstName}
                                                                onChange={handleFirstNameChange}
                                                                type="text"

                                                            />
                                                            {errors.firstName && (<label className="error mt-2 text-danger">{errors.firstName}</label>)}
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Last name</Label>
                                                            <Input
                                                                name="last_name"
                                                                className="form-control"
                                                                placeholder="Enter your last name"
                                                                value={lastName}
                                                                onChange={handleLastNameChange}
                                                                type="text"

                                                            />
                                                            {errors.lastName && (<label className="error mt-2 text-danger">{errors.lastName}</label>)}
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Telephone</Label>
                                                            <Input
                                                                name="telephone"
                                                                className="form-control"
                                                                placeholder="Enter your phone number (optional)"
                                                                value={telephone}
                                                                onChange={handleTelephoneChange}
                                                                type="text"

                                                            />
                                                            {errors.telephone && (<label className="error mt-2 text-danger">{errors.telephone}</label>)}
                                                        </div>
                                                        <div className="mb-4">
                                                            <label>Your reference currency</label>
                                                            <select
                                                                className="form-control "
                                                                value={currency}
                                                                onChange={handleCurrencyChange}
                                                            >
                                                                <option value='EUR' >Euro</option>
                                                            </select>
                                                            {errors.currency && (<label className="error mt-2 text-danger">{errors.currency}</label>)}
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Company (optional)</Label>
                                                            <Input
                                                                name="company"
                                                                value={company}
                                                                type="text"
                                                                placeholder="Your Company Name"
                                                                onChange={handleCompanyChange}

                                                            />
                                                            {errors.company && (<label className="error mt-2 text-danger">{errors.company}</label>)}

                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Address</Label>
                                                            <Input
                                                                name="Address"
                                                                value={address}
                                                                type="text"
                                                                placeholder="Your Address Name"
                                                                onChange={handleAddressChange}

                                                            />
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Post Code</Label>
                                                            <Input
                                                                name="PostCode"
                                                                value={postcode}
                                                                type="text"
                                                                placeholder="Your Post Code"
                                                                onChange={handlePostcodeChange}

                                                            />
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">City</Label>
                                                            <Input
                                                                name="City"
                                                                value={city}
                                                                type="text"
                                                                placeholder="City"
                                                                onChange={handleCityChange}

                                                            />
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Country</Label>
                                                            <Input
                                                                name="Country"
                                                                value={country}
                                                                type="text"
                                                                placeholder="Country"
                                                                onChange={handleCountryChange}

                                                            />
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Company VAT (optional)</Label>
                                                            <Input
                                                                name="company_vat"
                                                                value={vat}
                                                                type="text"
                                                                placeholder="Your Company VAT"
                                                                onChange={handleVatChange}

                                                            />
                                                            {errors.vat && (<label className="error mt-2 text-danger">{errors.vat}</label>)}

                                                        </div>

                                                        <div className="mb-4">
                                                            <Label className="form-label">Email</Label>
                                                            <Input
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Enter email"
                                                                value={email}
                                                                onChange={handleEmailChange}
                                                                type="email"
                                                                autoComplete={false}

                                                            />
                                                            {errors.email && (<label className="error mt-2 text-danger">{errors.email}</label>)}
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Password</Label>
                                                            <Input
                                                                type="password"
                                                                placeholder="Enter Password"
                                                                value={password}
                                                                onChange={handlePasswordChange}
                                                                autoComplete={'new-password'}

                                                            />
                                                            {errors.password && (<label className="error mt-2 text-danger">{errors.password}</label>)}

                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Confirm Password </Label>
                                                            <Input
                                                                type="password"
                                                                placeholder="Confirm Password"
                                                                onChange={handleConfirmPasswordChange}
                                                                value={confirmPassword}
                                                                autoComplete={'new-password'}


                                                            />
                                                            {errors.confirmPassword && (<label className="error mt-2 text-danger">{errors.confirmPassword}</label>)}

                                                        </div>
                                                        <div className="d-grid mt-4">
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                type="submit"
                                                            >
                                                                Register
                                                            </button>

                                                            {errors.submitError && (<label className="error mt-2 text-danger">{errors.submitError}</label>)}


                                                        </div>


                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>

                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <PublicFooter/>
        </React.Fragment>
    );


}


export default MoondanceRegister;