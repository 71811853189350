import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, CardBody, Col, Row, CardTitle } from "reactstrap";
import ReactApexChart from "react-apexcharts"

import { formatCurrency, formatPercentage, formatNumber } from "../../lib/formatData";
import MoondanceLayout from "../../lib/layout.js";


export default function WaterFallChart(props) {
  const [fullScreen, setFullScreen] = useState(false)
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([])

  const [horizontal, set_horizontal] = useState(false)
  async function downloadSVG(datatype) {
    const chartInstance = chartRef.current; // Access the chart DOM element

    if (!chartInstance || !chartInstance.chart || !chartInstance.chart.exports) {
      console.error("Chart instance or necessary properties are undefined");
      return;
    }

    // Use the correct method for exporting the chart based on the datatype
    if (datatype === "png") {
      await chartInstance.chart.exports.exportToPng();
    } else if (datatype === "svg") {
      await chartInstance.chart.exports.exportToSVG();
    } else {
      console.error("Unsupported datatype");
    }
  }



  useEffect(() => {
    //console.log('props', props)
    let newChartData = {
      series: [
        {
          name: 'Range',
          data: props.data
        },
      ],
      options: {
        toolbar: {
          show: false,
        },
        dataLabels: {
          formatter: (val) => {
            return val / 1000 + 'K'
          },
        },
        chart: {
          type: 'rangeBar',
          toolbar: {
            show: false // this hides the toolbar
          },
        },
        stroke: (props.options.stroke ? props.options.stroke : {
          width: 1,
          colors: ['#CCCCCC']
        }),
        plotOptions: {

          bar: {
            horizontal: horizontal,
          },
          dataLabels: {
            enabled: true,
          }
        },
        yaxis: (!horizontal ?
          {
            type: 'category',
            categories: props.options.yaxis.categories || [],
            labels: { formatter: function (value) { return MoondanceLayout.formatNumber(value, 0) + ' k' } }
          } :
          { labels: { formatter: function (value) { return value } } }),
        xaxis: (horizontal ?
          {
            type: 'category',
            categories: props.options.yaxis.categories || [],
            labels: { formatter: function (value) { return MoondanceLayout.formatNumber(value, 0) + ' k' } }
          } :
          { labels: { formatter: function (value) { return value } } }),
        annotations: {
          yaxis: (props.options.annotations && props.options.annotations.xaxis ? props.options.annotations.xaxis : []),
          points:
            (horizontal ?
              props.data.map(function (item) {
                return {
                  y: item.x,
                  x: (item.value < 0 ?
                    (item.y[1] < item.y[0] ? item.y[0] : item.y[1]) :
                    (item.y[1] >= item.y[0] ? item.y[1] : item.y[0])
                  ),
                  seriesIndex: 0,
                  marker: {
                    size: 0,
                    strokeWidth: 0,
                    radius: 0,
                  },
                  label: {
                    offsetY: 8,
                    offsetX: -40,
                    borderWidth: 0,
                    style: {
                      color: '#000000',
                      fontSize: '12px',
                      fontWeight: 600,

                    },
                    text: formatNumber(item.value) + ' k' + (props.currency ? MoondanceLayout.currencySymbol(props.currency) : '€')
                  }
                }
              }) :
              props.data.map(function (item) {
                return {
                  x: item.x,
                  y: (item.value < 0 ?
                    (item.y[1] < item.y[0] ? item.y[0] : item.y[1]) :
                    (item.y[1] >= item.y[0] ? item.y[1] : item.y[0])
                  ),
                  seriesIndex: 0,
                  marker: {
                    size: 0,
                    strokeWidth: 0,
                    radius: 0,
                  },
                  label: {
                    offsetX: 8,
                    offsetY: 0,
                    borderWidth: 0,
                    style: {
                      color: '#000000',
                      fontSize: '12px',
                      fontWeight: 600,

                    },
                    text: formatNumber(item.value) + ' k' + (props.currency ? MoondanceLayout.currencySymbol(props.currency) : '€')
                  }
                }
              })
            )
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const formattedValue = formatNumber(props.data[dataPointIndex].value);
            var returnHtml = '<strong>' + props.data[dataPointIndex].x + '</strong>' + formattedValue + ' k' + (props.currency ? MoondanceLayout.currencySymbol(props.currency) : '€');
            if (props.data[dataPointIndex].tooltipLegend) {
              returnHtml += '<p><sm>' + props.data[dataPointIndex].tooltipLegend + '</sm></p>'
            }
            return returnHtml
          },
        },
      }
    }

   // console.log('newChartData', newChartData)
    setChartData(newChartData);

  }, [props.data, props.options, props.currency, horizontal])


  function showChart() {
    return (
      <>
        <ReactApexChart
          ref={chartRef}
          key={chartRef + '_' + horizontal + '_' + fullScreen + '_' + props.currency}
          options={(chartData.options ? chartData.options : {})}
          series={(chartData.series ? chartData.series : [])}
          type="rangeBar"
          height={(fullScreen ? (window.innerHeight - 100) : (props.height ? props.height : 350))}
          title={props.title}
          className="apex-charts"

        />
        <button className="btn btn-link" onClick={() => { set_horizontal(!horizontal) }} ><span className="mdi mdi-autorenew"></span> Rotate </button>
        <button className="btn btn-link" onClick={() => { setFullScreen(!fullScreen) }} > {(fullScreen ? <><span className="bx bx-collapse"></span> Reduce</> : <><span className="bx bx-expand"></span> Full Screen</>)} </button>
        <button className="btn btn-link" onClick={() => { downloadSVG('png') }} > <span className="bx bx-export"></span> Export </button>
      </>
    )

  }
  return (
    <React.Fragment>
      {(fullScreen ?
        <div style={{ zIndex: 100000, width: '100%', height: '100%', backgroundColor: 'white', minWidth: '100%', minHeight: '100%', position: 'fixed', top: '0', left: '0' }}>{showChart()}</div>
        : <>{showChart()}</>
      )}


    </React.Fragment>


  )
}
/*
 <table className="table">
    {
      props.data.map(
        (item, index) => (
          <tr>
          <td>{item.x}</td>
            <td className="text-end">{formatCurrency(item.value)}</td>
            <td className="text-end">{(index >0 ?formatPercentage(-item.value/props.data[0].value,0):'')}</td>
              
          </tr> 
        )
      )
    }
    </table>
    */
/*   {
              fillColor: '#adb5bd',
              x: 'Capital Called',
              y: [0, -returnData.summary.t_cc_eur],
              value: -returnData.summary.t_cc_eur,
              tooltipLegend: formatPercentage(returnData.summary.t_cc_eur/returnData.summary.t_c_eur) +' of total Commitment',
            }];*/