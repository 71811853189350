import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
} from "reactstrap";


import MoondanceLayout from "../lib/layout.js";

import ProgressBar from 'react-bootstrap/ProgressBar';
import { MoondanceContext } from '../../App.js';
import { formatNumber, formatCurrency, formatPercentage } from "../lib/formatData.js";

import UseMoondanceApi from "../lib/api";


export function TotalPositionsCard(props) {
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='Total Commitment  '
      value={(props.loading ? 'loading' : formatNumber(props.data.summary.total_commited_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency))}
      value_sub={(props.loading ? 'loading' : formatNumber(props.data.summary.total_count_positions,0) + ' Positions')}
      
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.smWidth: 3)}
    />
  )
}
export function TotalCalledCard(props) {
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='Capital Calls'
      value={(props.loading ? 'loading' : formatNumber(props.data.summary.total_capital_call_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency))}
      value_sub={(props.loading ? 'loading' : formatPercentage(props.data.summary.total_capital_call_in_user_currency / props.data.summary.total_commited_in_user_currency) + ' of total commitment')}
      
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.smWidth: 3)}
    />
  )
}
export function TotalCallableCard(props) {
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='Expected Callable  '
      value={(
        props.loading ? 'loading' :
          <span className={
            (
              props.data.summary.total_expected_remaining_in_user_currency > 0
                ? "badge bg-danger me-1"
                : (props.data.summary.total_expected_remaining_in_user_currency < 0
                  ? "badge bg-success me-1"
                  : ''
                )
            )
          } > {formatNumber(props.data.summary.total_expected_remaining_in_user_currency / 1000, 0)} k{MoondanceLayout.currencySymbol(userCurrency)}</span>
      )}
      value_sub={(props.loading ? 'loading' : formatPercentage(props.data.summary.total_expected_remaining_in_user_currency / props.data.summary.total_commited_in_user_currency) + ' of total commitment')}
      text='Total Callable '
      subText={(props.loading ? 'loading' : formatNumber(props.data.summary.total_remaining_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency))}
     
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.mdWidth: 3)}
      xsWidth={(props.xsWidth ? props.xsWidth: 6)}
    />
  )
}
export function MOICCard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='MOIC'
      value={(props.loading ? 'loading' : formatNumber(props.data.summary.total_moic_in_user_currency, 2) + 'x')}
      subText='Multiple of invested capital'
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.mdWidth: 3)}
      xsWidth={(props.xsWidth ? props.xsWidth: 6)}
    />
  );
}
export function DPICard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='DPI'
      value={(props.loading ? 'loading' : formatNumber(props.data.summary.total_distributed_in_user_currency/props.data.summary.total_capital_call_in_user_currency, 2))+'x'}
      subText='Distributions to Paid-In Capital'
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.mdWidth: 3)}
      xsWidth={(props.xsWidth ? props.xsWidth: 6)}
    />
  );
}
export function TVPICard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='TVPI'
      value={(props.loading ? 'loading' : formatNumber(props.data.summary.total_tvpi_in_user_currency, 2) + 'x')}
      subText='Total Value to Paid-In Capital'
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.mdWidth: 3)}
      xsWidth={(props.xsWidth ? props.xsWidth: 6)}
    />
  );
}
export function IRRCard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='IRR'
      value={(props.loading ? 'loading' : formatPercentage(props.data.summary.total_irr, 4))}
      subText='Internal Rate of Return.'
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.mdWidth: 3)}
      xsWidth={(props.xsWidth ? props.xsWidth: 6)}
    />
  );
}
export function CapitalCalledCard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
       xlWidth={(props.xlWidth ? props.xlWidth: 6)}
      smWidth={(props.width ? props.width: 6)}
      cardBody={
        MoondanceLayout.progressBar({
          title: 'Capital Called',
          icon: 'text-info mdi mdi-bank',
          style: 'dark',
          label: props.loading ? 'loading' : formatCurrency(props.data.summary.total_capital_call_in_user_currency, userCurrency),
          value: props.loading
            ? 'loading'
            : formatCurrency(props.data.summary.total_capital_call_in_user_currency, userCurrency) +
              ' / ' +
              formatCurrency(props.data.summary.total_commited_in_user_currency, userCurrency),
          value_pct: props.loading ? 0 : props.data.summary.total_capital_called_in_percentage,
          subText:
            'Current Drawdown : ' +
            (props.loading
              ? 'loading'
              : formatPercentage(props.data.summary.total_capital_call_in_user_currency / props.data.summary.total_commited_in_user_currency) +
                ' of total commitment'),
        })
      }
    />
  );
}
export function DistributedCard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='Distributed'
      value={props.loading ? 'loading' : formatNumber(props.data.summary.total_distributed_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.mdWidth: 3)}
      xsWidth={(props.xsWidth ? props.xsWidth: 6)}
    />
  );
}
export function NAVCard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
      title='Net Asset Value'
      value={props.loading ? 'loading' : formatNumber(props.data.summary.total_nav_in_user_currency_current_rate / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.mdWidth: 3)}
      xsWidth={(props.xsWidth ? props.xsWidth: 6)}
    />
      
    
  );
}
export function GainLossesCard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card

      title='Gain & Losses'
      xlWidth={(props.xlWidth ? props.xlWidth: 3)}
      smWidth={(props.smWidth ? props.smWidth: 3)}
      mdWidth={(props.mdWidth ? props.mdWidth: 3)}
      xsWidth={(props.xsWidth ? props.xsWidth: 6)}
    
      value={
        <span className={props.loading ? '' : props.data.summary.total_gain_losses_in_user_currency > 0 ? "badge bg-success me-1" : props.data.summary.total_gain_losses_in_user_currency < 0 ? "badge bg-danger me-1" : ''}>
          {props.loading ? 'loading' : formatNumber(props.data.summary.total_gain_losses_in_user_currency / 1000, 0) + ' k' + MoondanceLayout.currencySymbol(userCurrency)}
        </span>
      }
    />
  );
}
export function DistributionNAVCard(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  return (
    <MoondanceLayout.card
       xlWidth={(props.xlWidth ? props.xlWidth: 6)}
      smWidth={(props.width ? props.width: 6)}
      cardBody={
        MoondanceLayout.progressBar({
          title: (
            <span>
              <span className="badge bg-dark">Distribution</span> +{' '}
              <span className="badge bg-success">Net Asset Value</span>
            </span>
          ),
          icon: 'text-success mdi mdi-bank',
          style: 'successn',
          label: props.loading ? 'loading' : formatCurrency(props.data.summary.total_distributed_in_user_currency, userCurrency),
          value: props.loading
            ? 'loading'
            : formatCurrency(props.data.summary.total_capital_call_in_user_currency, userCurrency) +
              ' / ' +
              formatCurrency(props.data.summary.total_commited_in_user_currency, userCurrency),
          subText:
            'Current Gain : ' +
            (props.loading
              ? 'loading'
              : formatCurrency(props.data.summary.total_gain_losses_in_user_currency, userCurrency) + ' of total commitment'),
          custom_progressBar: (
            <ProgressBar>
              <ProgressBar
                variant="dark"
                label={props.loading ? 'loading' : formatCurrency(props.data.summary.total_distributed_in_user_currency, userCurrency)}
                now={props.loading ? 0 : Math.round((props.data.summary.total_distributed_in_user_currency / props.data.summary.total_commited_in_user_currency) * 100)}
                max={100}
                key={1}
              />
              <ProgressBar
                striped
                variant="success"
                label={props.loading ? 'loading' : formatCurrency(props.data.summary.total_nav_in_user_currency, userCurrency)}
                now={props.loading ? 0 : Math.round((props.data.summary.total_nav_in_user_currency / props.data.summary.total_commited_in_user_currency) * 100)}
                max={100}
                key={2}
              />
            </ProgressBar>
          ),
        })
      }
    />
  );
}

export function DashBoardSummary(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  console.log('DashBoardSummary', props);
  return (
    <React.Fragment>

      <Row>
        <TotalPositionsCard data={props.data} />
        <TotalCalledCard data={props.data} />
        <TotalCallableCard data={props.data} />
        <CapitalCalledCard data={props.data} />
        

      </Row>
      <Row>
      <DistributedCard data={props.data} />
      <NAVCard data={props.data} />
      <GainLossesCard data={props.data} />
      <DistributionNAVCard data={props.data} />
      
     

      </Row>
      <Row>

        <MOICCard data={props.data} />
        <DPICard data={props.data} />
        <TVPICard data={props.data} />
        <IRRCard data={props.data} />
       



      </Row>


    </React.Fragment >
  )
}


export async function FetchYearlyData(api_parameters) {


  const moondanceApi = UseMoondanceApi();
  const [min_year, set_min_year] = React.useState(0);
  const [max_year, set_max_year] = React.useState(0);

  try {
   
    if (api_parameters.account && api_parameters.status) {
      const return_data = await moondanceApi.fetchYearlyTransactionPerPosition(api_parameters);
      // Extract the year values and convert them to numbers
      const years = return_data.data.map(item => Number(item.year));
      // Find the minimum and maximum year values
      let min_year = Math.min(...years)         
      let max_year = Math.max(...years)
      set_min_year(min_year);
      set_max_year(min_year);
      let positionFlow = {};

      positionFlow['meta_data']= []
      positionFlow['meta_data']['min_year'] = min_year
      positionFlow['meta_data']['max_year'] = max_year
      
      return_data.data.forEach((item) => {
        if (!positionFlow[item.position_id]) {

          positionFlow[item.position_id] = {
            id: item.position_id,
            name: item.name,
            balance_in_user_currency: {},
            cash_in_in_user_currency: {},
            cash_out_in_user_currency: {},
            balance_in_commitment_pct: {},
            cash_in_in_commitment_pct: {},
            cash_out_in_commitment_pct: {},
          }

          for( let i = min_year; i <= max_year; i++){
            positionFlow[item.position_id].balance_in_user_currency[i] = 0;
            positionFlow[item.position_id].cash_in_in_user_currency[i] = 0;
            positionFlow[item.position_id].cash_out_in_user_currency[i] = 0;
            positionFlow[item.position_id].balance_in_commitment_pct[i] = 0;
            positionFlow[item.position_id].cash_in_in_commitment_pct[i] = 0;
            positionFlow[item.position_id].cash_out_in_commitment_pct[i] = 0;
          }

        }
        if (item.year !== undefined && item.year !== null) {
          positionFlow[item.position_id].balance_in_user_currency[item.year] = item.balance_in_user_currency;
          positionFlow[item.position_id].cash_in_in_user_currency[item.year] = item.cash_in_in_user_currency;
          positionFlow[item.position_id].cash_out_in_user_currency[item.year] = item.cash_out_in_user_currency;
          positionFlow[item.position_id].balance_in_commitment_pct[item.year] = item.balance_in_commitment_pct;
          positionFlow[item.position_id].cash_in_in_commitment_pct[item.year] = item.cash_in_in_commitment_pct;
          positionFlow[item.position_id].cash_out_in_commitment_pct[item.year] = item.cash_out_in_commitment_pct;
        }
      });
      return positionFlow
    }
  } catch (error) {
    console.log(error);
  }
}