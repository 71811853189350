import React, { useState, useContext, useEffect, useRef } from "react";
import UseMoondanceApi from "../lib/api";
import MoondanceTable from "../lib/components/MoondanceTable.js";
import { useParams, Link, useNavigate } from 'react-router-dom';
import MoondanceLayout from "../lib/layout.js";

import ReactApexChart from "react-apexcharts"
import NavChart from "../lib/charts/navChart.js";
import Select from "react-select";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane,
    NavItem,
    NavLink,
    CardFooter, CardHeader, UncontrolledAlert, InputGroup, Label

} from "reactstrap";

import Flatpickr from "react-flatpickr";;

const currentDate = new Date();


const ExchangeRatesHistory = () => {
    document.title = "Dashboard | Moondance";

    const flatpickrRef_in = useRef(null);
    const flatpickrRef_out = useRef(null);
    const moondanceApi = UseMoondanceApi();
    const [data, setData] = useState({});
    const [dateIn, setDateIn] = useState(MoondanceLayout.formatDate(new Date(currentDate.setMonth(currentDate.getMonth() - 12))));
    const [dateOut, setDateOut] = useState(MoondanceLayout.formatDate(new Date()));
    const [to_currency, setToCurrency] = useState('USD');
    const [from_currency, setFromCurrency] = useState('EUR');
    const [chartData, setChartData] = useState({});
    const [availableCurrency, setAvailableCurrency] = useState([
        { value: 'EUR', label: 'Euro €' },
        { value: 'USD', label: 'US Dollar' },
        { value: 'GBP', label: 'UK Sterling Pound' },
        { value: 'CHF', label: 'Swiss Franc' },
    ]);
    const [fullScreen, setFullScreen] = useState(false)
    const chartRef = useRef(null);
    function handleSelectDateIn(selectedDates, dateStr, instance) {
        setDateIn(dateStr);
    }

    function handleSelectDateOut(selectedDates, dateStr, instance) {
        setDateOut(dateStr);
    }
    const fetchExchangeRateSerie = async () => {
        console.log('fetchExchangeRateSerie')
        console.log(dateIn, dateOut, from_currency, to_currency)
        let api_parameters = {
            date_in: MoondanceLayout.formatDate_DMY_to_YMD(dateIn),
            date_out: MoondanceLayout.formatDate_DMY_to_YMD(dateOut),
            from_currency: from_currency,
            to_currency: to_currency,
        }
        console.log(api_parameters)
        console.log(from_currency, to_currency)

        const exchangeRateSerie = await moondanceApi.getExchangeRateSerie(api_parameters);
        if (exchangeRateSerie && exchangeRateSerie.data) {
            setData(exchangeRateSerie.data)
            console.log(exchangeRateSerie.data)
        }


    }

    useEffect(() => {

        console.log('fetchExchangeRateSerie')
        fetchExchangeRateSerie();
    }, [dateIn, dateOut, from_currency, to_currency]);


    useEffect(() => {
        //console.log('props.data', props.data)
        if (data && data.length) {
            var serie = []
            data.map((item) => {
                var thisDate = item.timestamp * 1000
                serie.push([thisDate, item.rate])
            })
            var returnData = {}
            returnData.series = [{
                name: 'Rate',
                data: serie
            }]

            returnData.options = {
                chart: {
                    height: 350,
                    type: 'area',
                    zoom: {
                        enabled: false
                    },
                },

                markers: {
                    size: 2,
                },
                colors: [

                    MoondanceLayout.colorScheme.dark
                ],
                xaxis: {
                    type: 'datetime',
                },

                yaxis: {
                    labels: {
                        formatter: function (value) {

                            return MoondanceLayout.formatNumber(value,4)+' '+to_currency;

                        }
                    },
                },
            }

        };
        console.log('returnData', returnData)
        setChartData(returnData);
    }, [data])
    return (
        <React.Fragment >
            <div className='page-content' >
                <Container fluid={true}>
                    <Row style={{ marginBottom: '1em' }}>
                        <h1>Exchange Rate</h1>
                        
                        <Col xl={2} lg={3} sm={6}>
                            <Label>{from_currency}</Label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={availableCurrency.find(option => option.value === from_currency)}
                                onChange={selectedOption => setFromCurrency(selectedOption.value)}
                                placeholder="Select Currency"
                                options={availableCurrency}
                                classNamePrefix="select2-selection"
                                styles={MoondanceLayout.select2customStyles}
                            />
                        </Col>
                        <Col xl={2} lg={3} sm={6}>
                            <Label> to {to_currency}</Label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={availableCurrency.find(option => option.value === to_currency)}
                                onChange={selectedOption => setToCurrency(selectedOption.value)}
                                placeholder="Select Currency"
                                options={availableCurrency}
                                classNamePrefix="select2-selection"
                                styles={MoondanceLayout.select2customStyles}
                            />
                        </Col>
                        <Col xl={2} lg={3} sm={6}>
                            <Label>Start Date</Label>
                            <InputGroup>

                                <Flatpickr
                                    className="form-control "
                                    onChange={handleSelectDateIn}
                                    value={dateIn}
                                    options={{
                                        clickOpens: false,  // ensure this is true
                                        allowInput: true,   // ensure direct input is allowed
                                        dateFormat: "d/m/Y",
                                    }}
                                    ref={flatpickrRef_in}

                                />
                                <div className="input-group-append" onClick={() => flatpickrRef_in.current.flatpickr.open()}>
                                    <span className="input-group-text">
                                        <i className="mdi mdi-calendar"></i>
                                    </span>
                                </div>
                            </InputGroup>

                        </Col>
                        <Col xl={2} lg={3} sm={6}>

                            <Label>End Date</Label>
                            <InputGroup>
                                <Flatpickr
                                    className="form-control "
                                    onChange={handleSelectDateOut}
                                    value={dateOut}
                                    options={{
                                        clickOpens: false,  // ensure this is true
                                        allowInput: true,   // ensure direct input is allowed
                                        dateFormat: "d/m/Y",
                                    }}
                                    ref={flatpickrRef_out}

                                />
                                <div className="input-group-append" onClick={() => flatpickrRef_out.current.flatpickr.open()}>
                                    <span className="input-group-text">
                                        <i className="mdi mdi-calendar"></i>
                                    </span>
                                </div>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <Card>
                                <CardBody>
                                    <MoondanceTable
                                        key={`table-${dateIn} - ${dateOut} - ${from_currency} - ${to_currency}`}
                                        title="Exchange Rate"
                                        rows={data}
                                        defaultSort={{ key: 'timestamp', direction: 'descending' }}
                                        paginationSize={10}
                                        hideAiButton={true}
                                        columns={[
                                            { label: 'Date', field: 'timestamp', format: 'timestampToDate', align: 'left' },
                                            { label: 'Rate', field: 'rate', customFormat: (row) => { return (MoondanceLayout.formatNumber(row['rate'], 4) + ` ${to_currency}/${from_currency}`) }, align: 'left' },
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={9}>

                            {(chartData && chartData.series && chartData.series.length > 0 ?
                                <Card>
                                    <CardBody>
                                        <ReactApexChart
                                            ref={chartRef}
                                            key={`chart-${dateIn} - ${dateOut} - ${from_currency} - ${to_currency}`}
                                            options={chartData.options}
                                            series={chartData.series}
                                            height={(fullScreen ? (window.innerHeight - 100) : 700)}

                                            className="apex-charts"
                                        />
    
                                    </CardBody>
                                </Card>

                                : '')}

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}


export default ExchangeRatesHistory;
