import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
    Dropdown,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";

import logoDark from "../../assets/images/moondance_logo.png";


const TopBar = () => {

  const [serviceDropDown, setServiceDropDown] = useState('All');
    return (
        <React.Fragment >
            <header id="home-topbar" style={{position:'fixed',width:'100%',top:'0',zIndex:'10000'}} >
                <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid">
                        <span className="logo-sm">
                        <Link to="/home"><img src={logoDark} alt="logo-sm-dark" height="60" /></Link> 
                        </span>
                        <div className="d-flex justify-content-end " style={{ 'paddingTop': '0.5em' }}>
                        <Link style={{ 'marginRight': '0.5em' }}className="btn  btn-link"  to="/home/services" >Services</Link>
                        

                            <Link style={{ 'marginRight': '0.5em' }} className="btn  btn-bg btn-primary" to="/register" >Get Started <span className="mdi mdi-arrow-right-drop-circle"></span></Link>

                            <Link  style={{ 'marginRight': '1.5em' }}  className="btn  btn-light" to="/login" >Log in</Link>
           
                </div>
                    </div>
                </nav>
            </header>
            <div style={{height:'78px'}}>&nbsp;</div>
           
        </React.Fragment>
    )
}


export default TopBar;