import React from "react";
import { Navigate } from "react-router-dom";

// Moondance Pages
import MoonDanceDashboard from "../moondance/pages/dashboard";
import PositionDetail from "../moondance/pages/position_detail";
import AddPosition from "../moondance/pages/position_add_page.js";
import EditPosition from "../moondance/pages/position_edit.js";
import EditModel from "../moondance/pages/position_edit_model.js";
import DuplicatePosition from "../moondance/pages/position_duplicate.js";
import Transactions from "../moondance/pages/transactions_list.js";
import AddTransaction from "../moondance/pages/transactions_add_page.js";
import UpdateNav from "../moondance/pages/nav_update_page.js";
import NavBulk from "../moondance/pages/nav_update_bulk.js";
import Logs from "../moondance/pages/logs.js";
import Notes from "../moondance/pages/notes_list.js";
import AddNotes from "../moondance/pages/notes_add.js";
import EditNotes from "../moondance/pages/notes_edit.js";
import Contact from "../moondance/pages/contact.js";
import importXls from "../moondance/pages/import.js";
import ExchangeRatesHistory from "../moondance/pages/exchange_rate.js";

// Moondance Profile Pages
import UserProfile from "../moondance/profile/userProfile";
import Accounts from "../moondance/profile/accounts";
import SelectAccount from "../moondance/pages/account_select.js";

// Moondance Admin Pages
import MoonBase from "../moondance/admin/index.js";
import Users from "../moondance/admin/users.js";
import Clients from "../moondance/admin/clients.js";

// Moondance Authentication Pages
import MoondanceLogin from "../moondance/login/login";
import MoondanceLostPassword from "../moondance/login/lostPassword";
import MoondanceResetPassword from "../moondance/login/resetPassword";
import MoondanceRegister from "../moondance/login/register";
import ConfirmSignup from "../moondance/login/confirm-signup";
import Logout from "../Pages/Authentication/Logout";

// Public Website Pages
import PublicWebsite from "../moondance/publicWebsite/index.js";
import PublicServices from "../moondance/publicWebsite/services.js";
import GeneralSalesConditions from "../moondance/publicWebsite/gsc.js";
import ConfirmContactForm from "../moondance/publicWebsite/confirmContactForm.js";

// Utilities
import { useParams } from 'react-router-dom';

/*
 * Define routes that are protected by authentication
 * These routes will only be accessible after the user is authenticated
 */
const authProtectedRoutes = [
  // Dashboard and Position Pages
  { path: "/dashboard", component: <MoonDanceDashboard /> },
  { path: "/dashboard/:id", component: <MoonDanceDashboard /> },
  { path: "/position/:id", component: <PositionDetail /> },
  { path: "/addPosition", component: <AddPosition /> },
  { path: "/duplicatePosition/:id", component: <DuplicatePosition /> },
  
  // Transactions and Nav Pages
  { path: "/addTransaction", component: <AddTransaction /> },
  { path: "/addTransaction/:id", component: <AddTransaction /> },
  { path: "/addNav", component: <UpdateNav /> },
  { path: "/navbulk", component: <NavBulk /> },

  //Exchange Rate Pages
  { path: "/exchangeRatesHistory", component: <ExchangeRatesHistory /> },

  // Editing Pages
  { path: "/editPosition/:id", component: <EditPosition /> },
  { path: "/editModel/:id", component: <EditModel /> },
  
  // Logs and Notes Pages
  { path: "/logs", component: <Logs /> },
  { path: "/notes", component: <Notes /> },
  { path: "/notes/new/:id", component: <AddNotes /> },
  { path: "/notes/edit/:id", component: <EditNotes /> },
  { path: "/notes/new", component: <AddNotes /> },

  // Profile Pages
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/accounts", component: <Accounts /> },

  // Admin Pages
  { path: "/admin", component: <MoonBase /> },
  { path: "/admin/users/:id", component: <Users /> },
  { path: "/admin/clients/:id", component: <Clients /> },

  // Miscellaneous Pages
  { path: "/contact", component: <Contact /> },
  { path: "/selectAccount", component: <SelectAccount /> },
  { path: "/importXls", component: <importXls /> },



  // Catch-All Redirect (must be last)
  { 
    path: "/", 
    exact: true, 
    component: <Navigate to="/home" />, // Redirect to home page if no other route is matched
  },
];

/*
 * Define public routes that do not require authentication
 * These routes are accessible to all users, including unauthenticated users
 */
const publicRoutes = [
  // Authentication Pages
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <MoondanceLogin /> },
  { path: "/forgot-password", component: <MoondanceLostPassword /> },
  { path: "/reset-password/:code", component: <MoondanceResetPassword /> },
  { path: "/register", component: <MoondanceRegister /> },
  { path: "/confirm-signup/:token", component: <ConfirmSignup /> },
  
  // Public Website Pages
  { path: "/home", component: <PublicWebsite /> },

  { path: "/home/services", component: <PublicServices /> },
  { path: "/confirm_contact_form/:token", component: <ConfirmContactForm /> },
  { path: "/generalSalesConditions", component: <GeneralSalesConditions /> },
];

export { authProtectedRoutes, publicRoutes };
