import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import TopBar from '../publicWebsite/topbar.js';
import Carousel from '../publicWebsite/carousel.js';
import Contact from '../publicWebsite/contactForm.js';
import PublicFooter from '../publicWebsite/publicFooter.js';



import img1 from "../../assets/images/public/jcurve.png";
import video from "../../assets/images/2025_about_moondance.mp4";



const PublicWebsite = () => {


    useEffect(() => {
        document.body.className = "homePageBody";
    });


    return (

        <React.Fragment>
            <TopBar />
            <div className="'page-content publicHomePage" >
                <div id="homeBanner" >
                    <Container id="homeBannerContainer" className="container-lg homeContainer" >

                        <Row>
                            <h1>Simplify Your Private Equity Fund Management with MoonDance</h1><br />
                            <p>At MoonDance, we simplify the complexity of managing investments in private equity funds. Our platform is meticulously designed for investors and family offices, offering a seamless solution for the oversight and administration of Private Equity Fund portfolios.</p>
                        </Row>

                    </Container>
                </div>
                <div style={{ textAlign: "center", }}>
                    <Container id="homeVideo" className="container-lg homeContainer" >
                        <video controls style={{ maxWidth: '100%' }}>
                            <source src={video} type="video/mp4" />
                        </video>
                    </Container>
                </div>
                <div id="why" style={{backgroundColor: '#252b3b', color: '#FFFFFF',fontSize:'1.3em', lineHeight: '2em'}}>

                    <Container id="" className="container-lg homeContainer" style={{fontSize:'1em', lineHeight: '2em' }} >
                        <h1 style={{backgroundColor: '#252b3b', color: '#FFFFFF',fontSize:'2em', lineHeight: '2em'}} >WHY MOONDANCE</h1>
                        <p>Investing in private equity funds is an excellent complement to the stock market, offering attractive returns over time and enabling diversification across different geographies and strategies. However, private equity funds typically do not require capital upfront; instead, they issue capital calls during their initial years when needed to invest in assets according to their strategy.</p>
                        <p>Because capital is called incrementally, tracking commitments can be challenging. Investors may experience liquidity stress if multiple funds call for capital unexpectedly or if they lose track of their current engagements.</p>
                        <p>To address these challenges, family offices and informed investors often resort to building spreadsheets to monitor cash flows and estimate their engagements. Unfortunately, these homemade solutions are prone to errors. The complexity increases further when investing in funds in foreign currencies, where exchange rate variations introduce an additional layer of complexity.</p>
                        <p>Faced with these challenges, we have designed a tailored solution for private equity fund investors and family offices. With Moondance, investors can:</p>
                        <p>
                            <strong>Input Participation</strong>: Record capital calls and distributions, providing instant feedback on remaining commitments and fund performance.</p>
                            <p> <strong>Track Net Asset Values</strong>: Monitor updates provided by the fund's general partners, which helps estimate future cash flows and assess performance.</p>
                            <p><strong>Budget for Future Investments</strong>: Evaluate potential cash flow impacts and better prepare for and monitor future liquidity events.</p>
                            <p><strong>Generate Outputs</strong>: Produce charts and tables by fund type, geography, vintage year, and other dimensions, allowing investors to assess their exposure accurately.</p>
                            <p><strong>Access an Instant Dashboard</strong>: Manage exchange rate considerations seamlessly.</p>
                            <p><strong>Manage Multiple Portfolios</strong>: Family offices managing several customer portfolios invested in the same funds can input transactions for one position spread across multiple positions rapidly.</p>
                            
                        <p>With Moondance, investors and family offices save time, always have up-to-date information about their private equity funds, and can focus more on fund selection and other productive tasks for their clients.</p>

                    </Container>

                </div>







                <div className="page-content publicHomePage" >

                    <div className="homePageContactForm">
                        <Container className="container-lg" >

                            <Contact />
                        </Container>
                    </div>
                </div>
            </div>
            <PublicFooter />

        </React.Fragment>
    );

}


export default PublicWebsite;
/*<div className="page-content publicHomePage" >
                <Container id="homeCarousel" className="container-lg homeContainer" >
                    <Carousel />
                </Container>
            </div>
            */