import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import TopBar from '../publicWebsite/topbar.js';
import Carousel from '../publicWebsite/carousel.js';
import Contact from '../publicWebsite/contactForm.js';



import img1 from "../../assets/images/public/cashflow_pct_commit.png";
import img2 from "../../assets/images/public/cashflow_cumul.png";
import img3 from "../../assets/images/public/dashboard.png";
import { Link } from "react-router-dom";

import PublicFooter from '../publicWebsite/publicFooter.js';

const PublicServices = () => {


    useEffect(() => {
        document.body.className = "homePageBody";
    });


    return (

        <React.Fragment>
            <TopBar />
           
            <Container id="homeBannerContainer" className="container-lg" style={{ marginTop:'1em',fontSize: '1.2em', lineHeight: '2em' }}>
           
     

                <div className="page-content " >

                    <h2>Moondance</h2>
                    <p>Moondance was developed to meet our own personal needs, as well as to address a gap we identified in the market for family offices seeking a comprehensive solution to manage the customer positons as LP in private equity funds.
                    <br/><br/>Moondance is designed to manage your portfolio of private equity fund participations by tracking transactions and net asset values. It offers the capability to calculate various metrics, such as Internal Rate of Return (IRR), Total Value to Paid-In (TVPI), and more, across different currencies. 
                    <br/><br/>Additionally, Moondance enables you to forecast expected capital calls and distributions for your existing positions, aiding in budget management and commitment control. 
Family offices can manage multiple accounts and generate detailed statistics by vintage year, geographic area, type of fund, sectors, and more.</p><br/>
                    <h3>How It Works:</h3>
                    <ul>
                        <li style={{marginBottom:'2em'}}><img src={img1} className="d-block w-100" alt="Example of actual and expected cashflow for a given fund" />
                   Most private equity funds call for the majority of committed capital within the first five years and aim to distribute investment proceeds by the end of the fund's life, typically within 12 to 15 years. Using Moondance, you can estimate these cash flow patterns with based on historical aggregate data, allowing for better liquidity management and strategic planning.
                   <br/><br/>Moondance allows you to simulate capital calls for each fund position, enabling you to strategically reserve liquidity for future needs. Adjust these models in real time as new data becomes available, ensuring that your financial planning remains robust against actual capital calls and distributions.<br/>
                        
                        </li>
                        <li><strong>Global Cash Flow Estimation:</strong> <img src={img2} className="d-block w-100" alt="Example of actual and expected consolidated cashflow" />Gain a global perspective with aggregated models that estimate total expected cash flows, aiding in comprehensive financial planning across your portfolio.</li>
                        <li><strong>Dashboard and Analytics:</strong> Leverage our intuitive dashboard to monitor, analyze, and adjust your investment strategies. Visual analytics provide at-a-glance insights into your financial status, helping you make quick and effective decisions.</li>
                    </ul>

                 <br/><br/>

                    <h3>Why Moondance?</h3>
                    <p>Moondance is committed to delivering precision, efficiency, and clarity in financial forecasting for private equity investments. Trust us to enhance your financial navigation, leaving you more time to focus on strategic business decisions.</p>

                    <h3>Get Started Today:</h3>
                    <p>Discover how Moondance can transform your approach to investment planning and management.Schedule a demo contact our team to learn more about our platform and how it can be tailored to meet your needs.</p>
              
                    <h3>Disclaimer</h3><p>Please note that actual cash flows may differ based on fund strategy, macroeconomic events, or other factors. While Moondance provides guidance, it does not claim to forecast future cash flows exactly. This tool is intended to assist in planning and decision-making processes, not to serve as a precise predictor of future events.</p>

                </div>
            
            </Container>
            <div className="page-content publicHomePage" >
<hr/>
                <div className="homePageContactForm">
                    <Container className="container-lg" >

                        <Contact />
                    </Container>
                </div>
            </div>

        <PublicFooter/>
            </React.Fragment>
    );

    }


export default PublicServices;